<template>
	<div class="ctms_dlSrhBox">
		<div class="ctms_dlSrh">
			<input type="text" class="inp" placeholder="请输入合同编号" v-model="cno"/>
		</div>
		<button class="ctms_dlSrhBtn" @click="goto()" ><i class="iIcon"></i>搜 索</button>
	</div>
</template>
<script>
	export default {
		name: 'ctms_contract_search_list',
		props: {
			msg: String
		},
		data: function() {
			return {
				message: '',
				cno:''
			}
		},
		created() {
		},
		methods: {
			goto: function(){
				if(this.cno==null||this.cno==''){
					this.$message({
						message:'请输入合同编号',
						type:'error'
					})
				}else{
					this.$router.push({name:'ctms_c_lrxx',params:{cno:this.cno}});
				}
			}
		},
		watch: {
		},
		mounted: function() {}
	}
</script>